import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-layout',_vm._b({on:{"paramsChanged":_vm.reloadCollection},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.canCreate)?_c(VBtn,{attrs:{"depressed":"","color":"primary","to":{ name: 'taxonomy-create' }}},[_vm._v(" "+_vm._s(_vm.$t('Add new taxonomy'))+" ")]):_vm._e()]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'taxonomy-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.products",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.products.length)+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('button-action',{attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit'),"disabled":_vm.grid.loading,"to":{ name: 'taxonomy-update', params: { id: item.id } }}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('taxonomy-action-delete',{attrs:{"icon":"","value":item},on:{"success":_vm.onDeleteSuccess,"error":_vm.onRequestError}})]}},(_vm.canDelete)?{key:"grid.footer.actions",fn:function(){return [(_vm.errors)?_c('taxonomy-action-force-mass-delete-dialog',{attrs:{"items":_vm.selected},on:{"success":_vm.onMassDeleteSuccess,"error":_vm.onRequestError},model:{value:(_vm.errors),callback:function ($$v) {_vm.errors=$$v},expression:"errors"}}):_vm._e(),_c('div',{staticClass:"d-flex flex-gap-8"},[_c('taxonomy-action-mass-delete',{attrs:{"items":_vm.selected,"disabled":_vm.grid.loading || !_vm.selected.length},on:{"success":_vm.onMassDeleteSuccess,"error":function($event){_vm.errors = $event}}}),_c('button-action',{attrs:{"label":_vm.$t('Force delete'),"items":_vm.selected,"text":"","disabled":_vm.grid.loading || !_vm.selected.length},on:{"click":function($event){_vm.errors = { message: _vm.$t('Confirm force delete') }}}})],1)]},proxy:true}:null],null,true)},'grid-layout',{ grid: _vm.grid, params: _vm.params, options: _vm.options, title: _vm.$t('Taxonomies') },false))}
var staticRenderFns = []

export { render, staticRenderFns }
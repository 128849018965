import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('scrollable',{scopedSlots:_vm._u([{key:"sticky",fn:function(){return [_c(VCard,{staticClass:"mb-0",attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"d-flex"},[_c('span',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.$t('Manage Inventory')))])]),_c(VDivider)],1)]},proxy:true},{key:"scrollable-content",fn:function(ref){
var heightExceeded = ref.heightExceeded;
return [_c('div',{staticClass:"px-4"},[_c('resource-grid',{attrs:{"options":_vm.options,"items":_vm.grid.data,"hide-default-footer":"","expanded":_vm.expanded},on:{"onParamsChange":_vm.reloadCollection},scopedSlots:_vm._u([{key:"item",fn:function(val){return [_c('manage-inventory-row',{key:val.item.id,attrs:{"value":val.item},on:{"onTableRowToggle":function($event){return _vm.toggleExpand(val.item)}}})]}},{key:"expanded-item",fn:function(val){return [(_vm.hasChildren(val.item))?_c('td',{attrs:{"colspan":val.headers.length}},[_c(VDataTable,{attrs:{"headers":_vm.options.headers,"items":val.item.children,"item-key":"id","hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',{staticStyle:{"visibility":"collapse"}},[_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.value,attrs:{"width":header.width,"height":"0px"}})}),0)])]}},{key:"item",fn:function(nested){return [_c('manage-inventory-row',{key:nested.item.id,staticClass:"grey",class:_vm.$vuetify.theme.dark ? 'darken-3' : 'lighten-3',attrs:{"value":nested.item}}),_c(VDivider)]}}],null,true)})],1):_vm._e()]}}],null,true),model:{value:(_vm.params),callback:function ($$v) {_vm.params=$$v},expression:"params"}}),_c(VDivider),(!heightExceeded)?_c('div',{staticClass:"d-flex align-center pa-4 flex-gap-16 justify-end"},[_c('table-pagination',{attrs:{"meta":_vm.grid.meta},model:{value:(_vm.params),callback:function ($$v) {_vm.params=$$v},expression:"params"}})],1):_vm._e()],1)]}},{key:"sticky-bottom",fn:function(ref){
var heightExceeded = ref.heightExceeded;
return [_c(VDivider),(heightExceeded)?_c(VFooter,{staticClass:"d-flex align-center pa-4 flex-gap-16 justify-end"},[_c('table-pagination',{attrs:{"meta":_vm.grid.meta},model:{value:(_vm.params),callback:function ($$v) {_vm.params=$$v},expression:"params"}})],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }